export const ORGANISATION_REGISTER_REQUEST = 'ORGANISATION_REGISTER_REQUEST';
export const ORGANISATION_REGISTER_SUCCESS = 'ORGANISATION_REGISTER_SUCCESS';
export const ORGANISATION_REGISTER_FAIL = 'ORGANISATION_REGISTER_FAIL';

export const ORGANISATION_DETAILS_REQUEST = 'ORGANISATION_DETAILS_REQUEST';
export const ORGANISATION_DETAILS_SUCCESS = 'ORGANISATION_DETAILS_SUCCESS';
export const ORGANISATION_DETAILS_FAIL = 'ORGANISATION_DETAILS_FAIL';
export const ORGANISATION_DETAILS_RESET = 'ORGANISATION_DETAILS_RESET';

export const ORGANISATION_UPDATE_PROFILE_REQUEST =
  'ORGANISATION_UPDATE_PROFILE_REQUEST';
export const ORGANISATION_UPDATE_PROFILE_SUCCESS =
  'ORGANISATION_UPDATE_PROFILE_SUCCESS';
export const ORGANISATION_UPDATE_PROFILE_FAIL =
  'ORGANISATION_UPDATE_PROFILE_FAIL';
export const ORGANISATION_UPDATE_PROFILE_RESET =
  'ORGANISATION_UPDATE_PROFILE_RESET';

export const ORGANISATION_LIST_REQUEST = 'ORGANISATION_LIST_REQUEST';
export const ORGANISATION_LIST_SUCCESS = 'ORGANISATION_LIST_SUCCESS';
export const ORGANISATION_LIST_FAIL = 'ORGANISATION_LIST_FAIL';
export const ORGANISATION_LIST_RESET = 'ORGANISATION_LIST_RESET';

export const ORGANISATION_DELETE_REQUEST = 'ORGANISATION_DELETE_REQUEST';
export const ORGANISATION_DELETE_SUCCESS = 'ORGANISATION_DELETE_SUCCESS';
export const ORGANISATION_DELETE_FAIL = 'ORGANISATION_DELETE_FAIL';

export const ORGANISATION_UPDATE_REQUEST = 'ORGANISATION_UPDATE_REQUEST';
export const ORGANISATION_UPDATE_SUCCESS = 'ORGANISATION_UPDATE_SUCCESS';
export const ORGANISATION_UPDATE_FAIL = 'ORGANISATION_UPDATE_FAIL';
export const ORGANISATION_UPDATE_RESET = 'ORGANISATION_UPDATE_RESET';
