import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_CLEAR_ITEMS,
  CART_ADD_USER,
  CART_CLEAR_USER,
  CART_REMOVE_USER,
} from '../constants/orderCartConstants';

export const cartReducer = (
  // split state
  state = { cartItems: [], orderingUsers: [] },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;

      const existItem = state.cartItems.find((x) => x.product === item.product);
      // checks if exists. If it exits doesn't add cartItem again
      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.product !== action.payload),
      };
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };
    case CART_ADD_USER:
      const { user } = action.payload;
      console.log('reducer payload', user);
      const existUser = state.orderingUsers.find(
        (x) => x.user._id === user.user._id
      );
      if (existUser) {
        console.log('user exists');
        return {
          ...state,
          orderingUsers: state.orderingUsers.map((x) =>
            x.user === existUser.id ? user.id : x
          ),
        };
      } else {
        console.log('user added');
        return {
          ...state,
          orderingUsers: [...state.orderingUsers, user],
        };
      }

    case CART_REMOVE_USER:
      return {
        ...state,
        orderingUsers: state.orderingUsers.filter(
          (x) => x.user !== action.payload
        ),
      };

    case CART_CLEAR_USER:
      return {
        ...state,
        orderingUsers: [],
      };
    default:
      return state;
  }
};
