import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Barpass from '../../components/Barpass';
import { createTopUp } from '../../actions/topUpActions';
import Nav from '../../components/Nav';
const Bar = () => {
  const [opwaardeerbedrag, setOpwaardeerbedrag] = useState('');
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createTopUp({
        topup: opwaardeerbedrag,
        isPaid: false,
        IdealFee: 0.0,
        organisation: userInfo.organisation,
      })
    );
  };

  return (
    <div>
      <Nav />
      <div className='flex flex-col md:flex-row w-[100vw] h-[80vh]'>
        <div className='justify-start h-1/2 w-full mx-auto md:flex  align-middle md:h-full md:justify-center  md:flex-col  md:w-1/4 xl:w-1/6 '>
          <Barpass />
        </div>
        <div className='flex flex-row md:flex-col md:w-2/3'>
          <section className='grid grid-col-2 place-content-center align-middle w-full h-full  text-black text-xl font-bold text-center'>
            <form className='flex flex-col' method='post'>
              <label htmlFor='topup'>Opwaarder bedrag</label>
              <input
                type='number'
                step='any'
                name='bedrag'
                placeholder='Bedrag'
                value={opwaardeerbedrag}
                onChange={(e) => setOpwaardeerbedrag(e.target.value)}
                className='mt-10 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] block w-full rounded sm:text-sm focus:ring-1'
              />

              <button
                type='submit'
                onClick={submitHandler}
                className='appearance-none block rounded bg-[#10B981] text-white p-2 mt-10'
              >
                Opwaarderen
              </button>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Bar;
