import React from 'react';
import Nav from '../../components/Nav';

const Fail = () => {
  return (
    <div>
      <div>
        <Nav />
        <div className='flex flex-col justify-center items-center md:flex-row w-[100vw] h-[80vh]'>
          <h1 className='font-bold text-xl'>Oops dat ging niet heel lekker</h1>
        </div>
      </div>
    </div>
  );
};

export default Fail;
