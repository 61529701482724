import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../components/Loader';
import Nav from '../../components/Nav';
import Sidenav from '../../components/Sidenav';

import { createOrder } from '../../actions/orderActions';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import {
  addToUserCart,
  removeUserFromCart,
} from '../../actions/orderCartActions';
import { Link } from 'react-router-dom';

const FinishOrder = ({ history }) => {
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { product, loading } = productDetails;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success } = orderCreate;
  const cart = useSelector((state) => state.orderCart);
  const { cartItems, orderingUsers } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (user === undefined) {
      localStorage.getItem('orderingUsers');
    }
    if (product === undefined) {
      localStorage.getItem('cartItems');
    }
    if (success) {
      history.push(`/bestelling/${order._id}`);
    }
    // eslint-disable-next-line
  }, [success, product, user, dispatch]);
  const getQty = orderingUsers.map((user) => {
    if (user.qty === 0) {
      dispatch(removeUserFromCart(user.id));
      return 0;
    } else {
      return user.qty;
    }
  });
  const itemPrices = cartItems.map((item) => item.price);
  const totalQty = getQty.reduce((partialSum, a) => partialSum + a, 0);
  const allUsers = orderingUsers.map((user) => {
    return user.user._id;
  });

  const totalItemPrice = itemPrices.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  const totalPrice = totalItemPrice * totalQty;

  const adjustNumber = (num) => {
    const adjustedNum = num.toFixed(2).replace('.', ',');
    return adjustedNum;
  };

  const getQtyPP = () => {
    let qtyArr = [];
    for (let i = 0; i < orderingUsers.length; i++) {
      qtyArr.push(orderingUsers[i].qty);
    }
    return qtyArr;
  };

  const qtyPP = getQtyPP();
  const createOrderHandler = () => {
    dispatch(
      createOrder({
        qty: totalQty,
        orderItems: cartItems[0].name,
        users: allUsers,
        productPrice: cartItems[0].price,
        totalPrice: totalPrice,
        qtyPP,
        isPaid: false,
        organisation: userInfo.organisation,
      })
    );
  };

  return (
    <div className='overflow-hidden'>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {orderingUsers && cart && cartItems ? (
            <div>
              <Nav />
              <div className='flex w-[100vw]'>
                <Sidenav className='w-1/3' />
                <div className='flex flex-row w-full'>
                  <div className='flex flex-col align-center justify-center h-full w-full'>
                    <div className=' w-[95vw]  md:w-[30vw] mx-auto '>
                      {/* create card with product start  */}
                      {cart.orderingUsers.length > 0 ? (
                        <div>
                          <h1 className='text-5xl font-bold mb-10'>
                            De bestelling
                          </h1>
                          {/* Cart items start */}
                          {cartItems.map((item) => (
                            <div>
                              <div
                                key={item.product}
                                className=' w-[95vw] shadow-md rounded-xl lg:w-[30vw] md:w-[50vw] p-5 mb-5'
                              >
                                <h1 className='text-xl md:text-2xl font-medium '>
                                  {item.name}
                                </h1>

                                <br />
                                <div className='flex justify-between px-8 mb-7 pr-14'>
                                  <h2 className='text-slate-500'>Naam </h2>
                                  <h2 className='text-slate-500'>Qty</h2>
                                </div>
                                <div className='flex flex-col justify-between px-5 '>
                                  {orderingUsers.map((user) => (
                                    <div>
                                      {user.productId === item.product &&
                                      user.qty > 0 ? (
                                        <div className='flex justify-between gap-2'>
                                          <div className='flex'>
                                            <h1 className='text-lg md:text-xl mb-4'>
                                              {user.user.firstName +
                                                ' ' +
                                                user.user.lastName}
                                            </h1>
                                          </div>
                                          <div className='flex gap-4 mb-4'>
                                            <button
                                              className=' bg-[#F3F4F6] rounded-md flex justify-center items-center p-1'
                                              onClick={() =>
                                                dispatch(
                                                  addToUserCart({
                                                    user: user.user,
                                                    qty: user.qty++,
                                                    product: item.product,
                                                  })
                                                )
                                              }
                                            >
                                              <PlusIcon
                                                width={20}
                                                color='#34D399'
                                              />
                                            </button>
                                            <div className='font-semibold'>
                                              {user.qty}
                                            </div>
                                            <button
                                              className=' bg-[#F3F4F6] rounded-md p-1'
                                              onClick={() =>
                                                dispatch(
                                                  addToUserCart({
                                                    user: user.user,
                                                    qty: user.qty--,
                                                    product: cartItems.product,
                                                  })
                                                )
                                              }
                                            >
                                              <MinusIcon
                                                width={20}
                                                color='#34D399'
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ))}
                                  {/* User mapping end */}
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* Cart items end */}
                        </div>
                      ) : (
                        <div className='mx-auto'>
                          <h1 className='font-bold text-2xl '>
                            Winkelwagen Leeg
                          </h1>
                          <Link to={'/bar'}>
                            <button className='appearance-none block rounded bg-[#10B981] text-white p-2 w-48 mt-10'>
                              Naar homepagina
                            </button>
                          </Link>
                        </div>
                      )}
                      {/* create card with product end  */}
                      <div>
                        <div>
                          &emsp;<h1 className='text-2xl'>Te voldoen: </h1>€
                          {adjustNumber(totalItemPrice * totalQty)}
                        </div>

                        <div className='flex gap-10 mt-5'>
                          <button className='appearance-none block rounded bg-[#F3F4F6]  p-2 w-48 mx-auto font-semibold'>
                            Nog iets (komt heel snel 🥲)
                          </button>
                          <button
                            onClick={createOrderHandler}
                            className='appearance-none block rounded bg-[#34D399] text-white p-2 w-48 mx-auto font-semibold'
                          >
                            Betalen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {/* Ordering user end */}
        </div>
      )}
    </div>
  );
};

export default FinishOrder;
