import Nav from '../../components/Nav';
import Sidenav from '../../components/Sidenav';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserProfile } from '../../actions/userActions';
import Alert from '../../components/Alert';
const Profile = ({ history }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Wachtwoorden komen niet overeen');
    } else {
      dispatch(
        updateUserProfile({
          id: userInfo._id,
          firstName,
          lastName,
          email,
          password,
        })
      );
    }
    history.push('/bar');
  };

  useEffect(() => {
    if (!userInfo) {
      dispatch(getUserDetails('profile'));
    }
    if (userInfo) {
      setFirstName(userInfo.firstName);
      setLastName(userInfo.lastName);
      setEmail(userInfo.email);
    }
    // eslint-disable-next-line
  }, [dispatch, userInfo]);
  return (
    <div className='overflow-hidden'>
      <Nav />
      <div className='flex w-[100vw]'>
        <Sidenav className='w-1/3' userInfo={userInfo} />
        <div className='flex flex-col  w-3/4 ml-4     '>
          {message && <Alert message={message} />}

          <div className='flex flex-col xl:w-1/3 w-full '>
            <h1 className='font-bold text-4xl mb-10'>Jouw gegevens</h1>
            <div className='flex flex-col'>
              <form action='post' className='w-full'>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label
                      className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                      htmlFor='grid-first-name'
                    >
                      Voornaam
                    </label>
                    <input
                      className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'
                      id='grid-first-name'
                      type='text'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                    <label
                      className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                      htmlFor='grid-last-name'
                    >
                      Achternaam
                    </label>
                    <input
                      className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'
                      id='grid-last-name'
                      type='text'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className='w-full md:w-1/2 px-3'>
                    <label
                      className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                      htmlFor='grid-email'
                    >
                      Jouw Email
                    </label>
                    <input
                      className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                      id='grid-email'
                      type='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className='w-full justify-start flex mb-10'>
                  <div className='w-full md:w-1/2  '>
                    <button
                      className='w-full rounded h-10 bg-[#34D399] text-white font-semibold'
                      type='submit'
                      value='Wijzigen'
                      onClick={submitHandler}
                    >
                      Wijzigen
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Wachtwoord wijzigen */}
          <div className='flex flex-col w-full xl:w-1/3 '>
            <h1 className='font-bold text-4xl mb-10'>Wachtwoord wijzigen</h1>
            <div className='flex flex-row justify-end'>
              <form action='post' className='w-full'>
                <div className='flex flex-wrap -mx-3 mb-6'>
                  <div className='w-full md:w-1/2 px-3'>
                    <label
                      className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                      htmlFor='grid-password'
                    >
                      Nieuwe wachtwoord
                    </label>
                    <input
                      className='appearance-none block mt-3 w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                      id='grid-password'
                      type='password'
                      placeholder='Doe maar iets sterks'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className='w-full md:w-1/2 px-3 mt-3'>
                    <label
                      className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                      htmlFor='grid-confirm-password'
                    >
                      Bevestig nieuw wachtwoord
                    </label>
                    <input
                      className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                      id='grid-confirm-password'
                      type='password'
                      placeholder='Op één been kan je niet lopen'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className='w-full justify-start flex mb-10'>
                  <div className='w-full md:w-1/2 '>
                    <button
                      className='w-full rounded h-10 bg-[#34D399] text-white font-semibold'
                      type='submit'
                      value='Wijzig wachtwoord'
                      onClick={submitHandler}
                    >
                      Wijzigen
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
