import { Link } from 'react-router-dom';
import { logout } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { getUserDetails } from '../actions/userActions';
import {
  CART_CLEAR_ITEMS,
  CART_CLEAR_USER,
} from '../constants/orderCartConstants';
const Nav = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const clearCart = () => {
    dispatch({ type: CART_CLEAR_USER });
    dispatch({ type: CART_CLEAR_ITEMS });
  };
  useEffect(() => {
    if (!userInfo) {
      dispatch(getUserDetails('profile'));
    }
    // eslint-disable-next-line
  }, [dispatch, userInfo]);
  const logoutHandler = () => {
    dispatch(logout());
    history.push('/inloggen');
  };
  return (
    <nav className=' flex items-center justify-between flex-wrap bg-white p-6 w-[100vw]'>
      <Link
        to='/bar'
        className='flex items-center flex-shrink-0 text-[#10B981] text-shadow mr-6 text-4xl font-bold'
        onClick={() => clearCart()}
      >
        BarApp
      </Link>
      <Link
        to='/uitloggen'
        className='mt-4 lg:inline-block lg:mt-0 text-black font-medium text-xl hover:text-[#10B981] md:invisible  '
      >
        Uitloggen
      </Link>
      <div className='hidden w-[80%] max-w-[90rem] md:flex flex-end sm:hidden  lg:flex'>
        <div className='hidden text-sm md:flex md:justify-between md:w-full font-semibold right-2 '>
          <Link
            to='/bar'
            className=' mt-4 lg:inline-block lg:mt-0 text-black hover:text-[#10B981] mr-10 text-xl'
          >
            Home
          </Link>
          <Link
            to='/bar/bestellen'
            className='block mt-4 lg:inline-block lg:mt-0 text-black hover:text-[#10B981] mr-10 text-xl'
          >
            Bestellen
          </Link>
          <Link
            to='/bar/saldo'
            className='block mt-4 lg:inline-block lg:mt-0 text-black mr-10 hover:text-[#10B981] text-xl'
          >
            Saldo
          </Link>
          <Link
            to='/bar/profiel'
            className='block mt-4 lg:inline-block lg:mt-0 text-black mr-10 hover:text-[#10B981] text-xl'
          >
            Profiel
          </Link>

          <div
            onClick={logoutHandler}
            className='block mt-4 lg:inline-block lg:mt-0 text-black mr-10 hover:text-[#10B981] text-xl'
          >
            Uitloggen
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
