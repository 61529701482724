import React from 'react';
import { useSelector } from 'react-redux';
const HeroBanner = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <div className='w-[50vw] max-w-[60rem] md:w-[60vw] md:max-w-[90rem] lg:max-w-[90rem] h-80 bg-[#34D399] mb-20 mt-20 ml-10 rounded-xl text-7xl text-white md:flex  items-center relative hidden '>
      <div className='text-left ml-20 '>
        <h1 className='text-7xl font-bold relative mb-6 z-10'>Welkom bij</h1>
        <p className='text-5xl relative z-10'>{userInfo.organisation}</p>
      </div>
      <div className='absolute left-4 top-4 -z-1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='221.589'
          height='196.643'
          viewBox='0 0 221.589 196.643'
        >
          <path
            id='blob_1_'
            data-name='blob (1)'
            d='M119.184-53.038c23.522,15.49,37.578,48.335,32.271,75.873C146,50.23,121.192,72.461,97.67,92.828,74.291,113.051,52.06,131.7,29.828,131.84c-22.375.143-44.893-17.928-64.255-38.3C-53.646,73.178-69.71,50.66-68.993,28.859-68.419,6.915-51.065-14.313-31.7-29.659-12.483-45.006,8.745-54.616,35.852-60.5,62.817-66.233,95.662-68.385,119.184-53.038Z'
            transform='translate(69.016 64.802)'
            fill='#8ef1cd'
          />
        </svg>
      </div>
      <div className='absolute right-0 top-1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='131.102'
          height='207.768'
          viewBox='0 0 131.102 207.768'
        >
          <path
            id='Path_69'
            data-name='Path 69'
            d='M1556.168,264.732s-60.285-63.446-90.791-43.486-53.933,72.357-31.236,123.328,122.027,80.555,122.027,80.555Z'
            transform='translate(-1425.066 -217.361)'
            fill='#8ef1cd'
          />
        </svg>
      </div>
    </div>
  );
};

export default HeroBanner;
