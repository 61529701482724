import { addToCart, addToUserCart } from '../../actions/orderCartActions.js';
import React, { useEffect, useState } from 'react';
import Alert from '../../components/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers } from '../../actions/userActions.js';
import User from '../../components/User';
import { Loader } from '../../components/Loader.jsx';
import Sidenav from '../../components/Sidenav.jsx';
import Nav from '../../components/Nav.jsx';
import { Link } from 'react-router-dom';
const AddUserToCart = ({ match }) => {
  const productId = match.params.id;
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { loading, users } = userList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (users?.length < 1) {
      dispatch(listUsers(userInfo.organisation));
    }
    dispatch(addToCart(productId));
  }, [dispatch, productId, users, userInfo.organisation]);
  const orderCart = useSelector((state) => state.orderCart);
  const { cartItems } = orderCart;
  const userAdd = (user) => {
    setDisplay(true);
    dispatch(addToUserCart({ user, qty: 1, productId }));
  };

  return (
    <div className=''>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Nav />
          {display && (
            <div
              style={{
                zIndex: '100',
                width: '100%',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => setDisplay(false)}
            >
              {' '}
              <Alert message={'User added'} />
            </div>
          )}
          <div className='md:flex md:flex-row   w-[100vw] h-[90vh] bottom-0'>
            <Sidenav className='hidden md:w-1/3' />

            <div className='md:flex block mx-auto md:w-2/3 w-full h-full  items-center justify-center '>
              <div>
                <h1 className='font-bold text-3xl ml-8 mr-8'>
                  Wie wilt er een wat
                </h1>
                <section className='grid lg:grid-cols-4 grid-cols-2 auto-rows-auto gap-5  place-items-center h-1/2 '>
                  {users?.map((user, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        userAdd(user);
                      }}
                    >
                      <div>
                        {/* If product is 18+ */}
                        {cartItems[0]?.nixx !== false &&
                        user?.Nixx !== true ? null : (
                          <>
                            {user?.saldo > -10 ? (
                              <User key={user._id} user={user} />
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </section>
                <div className='mt-5 relative z-10'>
                  <Link to={`/bestellen/${productId}/afronden`}>
                    <button
                      type='submit'
                      className='appearance-none block rounded bg-[#10B981] text-white p-2 w-48 mx-auto '
                    >
                      Volgende
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddUserToCart;
