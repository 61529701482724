import React from 'react';
import { Link } from 'react-router-dom';
import homeImg from '../barapp-home.png';
const welcome = () => {
  return (
    <div className='flex h-[100vh] relative overflow-hidden z-10'>
      <div className='sm:flex sm:items-center sm:ml-5  sm:w-[100vw] md:w-[100vw] lg:[100vw] xl:w-[80vw] w-[60vw] h-full grid content-center justify-center'>
        <div className='w-full ml-10'>
          <h1 className='text-6xl font-bold'>
            Welkom bij <span className='text-[#10B981] inline'>BarApp</span>
          </h1>
          <h3 className='text-2xl mt-6 mb-6 text-[#10B981] font-bold'>
            Jouw barpartner
          </h3>

          <Link to='/inloggen' className='mt-10'>
            <h1 className='text-2xl font-bold inline mr-4 '>Start</h1>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-7 w-7 inline'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='3'
                d='M14 5l7 7m0 0l-7 7m7-7H3'
              />
            </svg>
          </Link>
        </div>
      </div>
      <div className='hidden  lg:block bg-[#10B981] w-[40vw] rounded-3xl relative -right-5'></div>
      <div className='hidden absolute -right-7 lg:flex h-full items-center'>
        <img
          src={homeImg}
          alt='barapp'
          className='rounded-3xl shadow-xl h-[50vh] w-full'
        />
      </div>
    </div>
  );
};

export default welcome;
