import axios from 'axios';
import {
  ORGANISATION_DETAILS_FAIL,
  ORGANISATION_DETAILS_REQUEST,
  ORGANISATION_DETAILS_SUCCESS,
  ORGANISATION_REGISTER_FAIL,
  ORGANISATION_REGISTER_REQUEST,
  ORGANISATION_REGISTER_SUCCESS,
  ORGANISATION_UPDATE_PROFILE_FAIL,
  ORGANISATION_UPDATE_PROFILE_REQUEST,
  ORGANISATION_UPDATE_PROFILE_SUCCESS,
} from '../constants/organisationConstants';
// const local = 'http://localhost:5000';
// const local = 'https://betaapi.barapp.nl';
const local = 'https://api.barapp.nl';
export const createOrganisation =
  (
    firstName,
    lastName,
    organisationName,
    email,
    iban,
    street,
    houseNumber,
    postalCode
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORGANISATION_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${local}/api/organisations`,
        {
          firstName,
          lastName,
          email,
          iban,
          organisationName,
          street,
          houseNumber,
          postalCode,
        },
        config
      );

      dispatch({
        type: ORGANISATION_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORGANISATION_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getOrganisationDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORGANISATION_DETAILS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${local}/api/users/${id}`, config);

    dispatch({
      type: ORGANISATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORGANISATION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateOrganisationProfile =
  (user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORGANISATION_UPDATE_PROFILE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${local}/api/users/profile`,
        user,
        config
      );

      dispatch({
        type: ORGANISATION_UPDATE_PROFILE_SUCCESS,
        payload: data,
      });

      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: ORGANISATION_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
