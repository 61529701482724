import {
  TOPUP_DETAILS_FAIL,
  TOPUP_DETAILS_SUCCESS,
  TOPUP_DETAILS_REQUEST,
  TOPUP_LIST_MY_REQUEST,
  TOPUP_LIST_MY_SUCCESS,
  TOPUP_LIST_MY_FAIL,
  TOPUP_LIST_FAIL,
  TOPUP_LIST_SUCCESS,
  TOPUP_LIST_REQUEST,
  TOPUP_LIST_MY_RESET,
  TOPUP_CREATE_REQUEST,
  TOPUP_CREATE_SUCCESS,
  TOPUP_CREATE_FAIL,
  TOPUP_CREATE_RESET,
  TOPUP_PAY_REQUEST,
  TOPUP_PAY_SUCCESS,
  TOPUP_PAY_FAIL,
  TOPUP_PAY_RESET,
} from '../constants/topUpConstants';

export const topUpDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case TOPUP_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOPUP_DETAILS_SUCCESS:
      return {
        loading: false,
        topUp: action.payload,
      };
    case TOPUP_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const topUpCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPUP_CREATE_REQUEST:
      return {
        loading: true,
      };
    case TOPUP_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        topup: action.payload,
      };
    case TOPUP_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOPUP_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const topUpListMyReducer = (state = { topUps: [] }, action) => {
  switch (action.type) {
    case TOPUP_LIST_MY_REQUEST:
      return {
        loading: true,
      };
    case TOPUP_LIST_MY_SUCCESS:
      return {
        loading: false,
        topUps: action.payload,
      };
    case TOPUP_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOPUP_LIST_MY_RESET:
      return { topUps: [] };
    default:
      return state;
  }
};

export const topUpListReducer = (state = { topUps: [] }, action) => {
  switch (action.type) {
    case TOPUP_LIST_REQUEST:
      return {
        loading: true,
      };
    case TOPUP_LIST_SUCCESS:
      return {
        loading: false,
        topUps: action.payload,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case TOPUP_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const topUpPayReducer = (state = {}, action) => {
  switch (action.type) {
    case TOPUP_PAY_REQUEST:
      return {
        loading: true,
      };
    case TOPUP_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case TOPUP_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case TOPUP_PAY_RESET:
      return {};
    default:
      return state;
  }
};
