import {
  IdealBankElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import Barpass from '../../components/Barpass';
import { useDispatch, useSelector } from 'react-redux';

import './stripe.css';
import axios from 'axios';
import Nav from '../../components/Nav';
import { createTopUp } from '../../actions/topUpActions';
import { useEffect } from 'react';
import { getUserDetails } from '../../actions/userActions';
import { useState } from 'react';
const Stripe = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const topUpCreate = useSelector((state) => state.topUpCreate);
  const { success, topup } = topUpCreate;
  const stripe = useStripe();
  const elements = useElements();
  const [bedrag, setBedrag] = useState(0);
  const idealBank = elements.getElement(IdealBankElement);

  useEffect(() => {
    if (!user.firstName) {
      dispatch(getUserDetails('profile'));
    }
    if (success) {
      submitPayment();
    }
    // eslint-disable-next-line
  }, [success]);
  const IDEAL_ELEMENT_OPTIONS = {
    // Custom styling can be passed to options when creating an Element
    style: {
      base: {
        padding: '10px 12px',
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBedrag(parseFloat(bedrag));
    let expected = parseFloat(user.saldo) + parseFloat(bedrag);
    console.log(expected);
    dispatch(
      createTopUp({
        user: user,
        topup: bedrag,
        isPaid: false,
        IdealFee: 0.29,
        organisation: userInfo.organisation,
        expected,
        oldSaldo: user.saldo,
      })
    );
    if (!stripe || !elements) {
      return;
    }
  };

  const submitPayment = async () => {
    const res = await axios.post(process.env.REACT_APP_STRIPE_SUBMIT_URL, {
      email: userInfo.email,
      bedrag,
    });

    var clientSecret = res.data.client_secret;

    const { error } = stripe.confirmIdealPayment(clientSecret, {
      payment_method: {
        ideal: idealBank,
        billing_details: {
          email: userInfo.email,
          name: userInfo.firstName + userInfo.lastName,
        },
      },
      return_url: `${
        process.env.REACT_APP_STRIPE_RETURN_URL + topup._id
      }/gelukt`,
    });

    if (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Nav />
      <div className='flex flex-col md:flex-row w-[100vw] h-[80vh]'>
        <div className='flex flex-col items-center h-1/2 w-full mx-auto md:flex  align-middle md:h-full md:justify-center  md:flex-col  md:w-1/4 xl:w-1/6 '>
          <div className=''>
            <Barpass />
          </div>
        </div>
        <div className='flex flex-row md:flex-col md:w-2/3'>
          <form
            className='grid grid-col-2 place-content-center align-middle w-full h-full  text-black text-xl font-bold text-center'
            onSubmit={handleSubmit}
          >
            <label className='w-[fit-content]'>
              iDEAL Bank
              <IdealBankElement options={IDEAL_ELEMENT_OPTIONS} />
            </label>
            <input
              type='number'
              step='any'
              name='bedrag'
              placeholder='Bedrag'
              onChange={(e) => setBedrag(e.target.value)}
              className='mt-10 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] block w-full rounded sm:text-sm focus:ring-1'
            />
            <button
              type='submit'
              disabled={!stripe}
              className='appearance-none block rounded bg-[#10B981] text-white p-2 mt-10'
            >
              Betalen
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Stripe;
