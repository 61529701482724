import axios from 'axios';
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_ADD_USER,
  CART_REMOVE_USER,
} from '../constants/orderCartConstants';
// const local = 'http://localhost:5000';
// const local = 'https://betaapi.barapp.nl';
const local = 'https://api.barapp.nl';
export const addToCart = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };
  const { data } = await axios.get(`${local}/api/products/${id}`, config);
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      price: data.price,
      nixx: data.Nixx,
    },
  });
  localStorage.setItem(
    'cartItems',
    JSON.stringify(getState().orderCart.cartItems)
  );
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem(
    'cartItems',
    JSON.stringify(getState().orderCart.cartItems)
  );
};

export const addToUserCart =
  (user, qty, product) => async (dispatch, getState) => {
    console.log('action', user);
    dispatch({
      type: CART_ADD_USER,
      payload: {
        user,
        qty,
        product,
      },
    });

    localStorage.setItem(
      'orderingUsers',
      JSON.stringify(getState().orderCart.orderingUsers)
    );
  };

export const removeUserFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_USER,
    payload: id,
  });

  localStorage.setItem(
    'orderingUsers',
    JSON.stringify(getState().orderCart.orderingUsers)
  );
};
