import Barpass from './Barpass';
import History from './History';

const Sidenav = () => {
  return (
    <div className='hidden md:flex flex-col w-1/3 2xl:w-1/6 mt-[2rem] '>
      <Barpass />
      <History />
    </div>
  );
};

export default Sidenav;
