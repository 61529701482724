import React from 'react';
import { Link } from 'react-router-dom';
import Barpass from '../../components/Barpass';
import Nav from '../../components/Nav';
import { ReactComponent as Ideal } from '../../ideal-logo.svg';
const Choose = () => {
  return (
    <div>
      <Nav />
      <div className='flex flex-col md:flex-row w-[100vw] h-[80vh]'>
        <div className='flex flex-col items-center h-1/2 w-full mx-auto md:flex  align-middle md:h-full md:justify-center  md:flex-col  md:w-1/4 xl:w-1/6 '>
          <div className=''>
            <Barpass />
          </div>
        </div>
        <div className='flex flex-row md:flex-col md:w-2/3'>
          <section className='grid grid-col-2 place-content-center align-middle w-full h-full  text-black text-xl  text-center'>
            <h1 className='text-4xl font-bold'>Betaalmethode</h1>
            <Link to='/opwaarderen/stripe' className='mt-5'>
              <span className='flex justify-start align-middle items-center mt-5'>
                <Ideal className='w-10  mr-5' />
                <h1 className='font-bold inline'>iDEAL</h1>
                <p className='ml-10 text-sm italic'>+ € 0,29</p>
              </span>
              <span className='flex justify-start items-center align-middle mt-2'>
                <p className=' text-sm font-semibold text-[#10B981] '>
                  Meteen op je account
                </p>
              </span>
            </Link>
            {/* <Link to='/opwaarderen/bar' className='mt-5'>
              <span className='flex justify-start align-middle items-center mt-5'>
                <h1 className='font-bold inline'>Via de bar</h1>
                <p className='ml-10 text-sm italic'>gratis</p>
              </span>
              <span className='flex justify-start items-center align-middle mt-2'>
                <p className=' text-sm font-semibold text-[#10B981] '>
                  Duurt langer
                </p>
              </span>
            </Link> */}
          </section>
        </div>
      </div>
    </div>
  );
};

export default Choose;
