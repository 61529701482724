import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userLoginReducer,
} from './reducers/userReducers';
import {
  productListReducer,
  productDetailsReducer,
} from './reducers/productReducer';
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderListMyReducer,
} from './reducers/orderReducer';

import { cartReducer } from './reducers/orderCartReducer';
import {
  topUpCreateReducer,
  topUpDetailsReducer,
  topUpListMyReducer,
  topUpPayReducer,
} from './reducers/topUpReducer';
const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  topUpCreate: topUpCreateReducer,
  topUpDetails: topUpDetailsReducer,
  topUpPaid: topUpPayReducer,
  topUpListMy: topUpListMyReducer,
  orderCart: cartReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const orderCartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : [];
const orderCartUsersFromStorage = localStorage.getItem('orderingUsers')
  ? JSON.parse(localStorage.getItem('orderingUsers'))
  : [];
const initialState = {
  cart: {
    cartItems: orderCartItemsFromStorage,
    orderingUsers: orderCartUsersFromStorage,
  },
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
