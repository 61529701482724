const Twofa = () => {
  return (
    <div>
      <div className='grid align-center text-center  place-content-center h-[100vh]'>
        <h1 className='text-4xl font-bold mb-10'>2FA instellen</h1>
        <form className='w-full max-w-lg mt-10'>
          <div className='flex flex-wrap -mx-3 mb-6'>
            <div className='w-full  px-3 mb-6 md:mb-0'>
              <label
                className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left'
                htmlFor='grid-first-name'
              >
                Telefoonummer
              </label>
              <input
                className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                id='grid-first-name'
                type='tel '
                placeholder='Jouw telefoonnummer'
              />
            </div>
          </div>
        </form>
        <div>
          <strong>Of</strong>
          <br />
          <br />
          <h3>Gebruik een app zoals Microsoft/Google Authenicator</h3>
        </div>
        <div className='flex items center space-x-6 max-w-lg mx-auto mt-10'>
          <input
            type='submit'
            className='appearance-none block rounded bg-[#10B981] w-[30rem] align-middle text-center py-3 px-4 leading-tight text-white font-bold'
            value='Doorgaan'
          ></input>
        </div>
      </div>
    </div>
  );
};

export default Twofa;
