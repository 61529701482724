import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../actions/userActions';
import Card from '../../components/Card';
import Nav from '../../components/Nav';
import Sidenav from '../../components/Sidenav';
import Barpass from '../../components/Barpass';
import {
  CART_CLEAR_ITEMS,
  CART_CLEAR_USER,
} from '../../constants/orderCartConstants';
import HeroBanner from '../../components/HeroBanner';

const Home = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    dispatch({ type: CART_CLEAR_USER });
    dispatch({ type: CART_CLEAR_ITEMS });
    if (userInfo === null || !userInfo || userInfo === undefined) {
      history.push('/inloggen');
    }
    if (!user.firstName) {
      dispatch(getUserDetails('profile'));
    }
    // eslint-disable-next-line
  }, [dispatch, userInfo, history, user]);

  return userInfo && user ? (
    <div className='overflow-hidden'>
      <Nav user={userInfo} />
      <div className='flex w-[100vw] '>
        <Sidenav userInfo={userInfo} className='md:w-1/3 ' />
        <div className='flex flex-row mx-auto md:ml-5'>
          <div>
            <div className='w-full md:w-3/4 md:hidden  block mx-auto'>
              <Barpass />
            </div>
            <div className='flex flex-col mb-20 '>
              <HeroBanner />
              <div className=' flex flex-row w-full h-5  md:ml-10 space-y-32 '>
                <h1 className=' text-2xl md:text-4xl font-bold'>
                  Wat zou je willen doen
                </h1>
              </div>

              <div className='mt-10 grid grid-cols-2 gap-y-8 justify-centerd	 md:flex md:flex-row md:ml-10 md:space-x-12 md:mt-10 md:h-full '>
                <Card title='Bestellen' link='/bar/bestellen' />
                <Card title='Saldo opwaarderen' link='/opwaarderen' />
                <Card title='Profiel' link='bar/profiel' />
                <Card title='Geschiedenis' link='/bar/geschiedenis' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default Home;
