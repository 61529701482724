import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './pages/login';
import Logout from './pages/Logout';
import Signup from './pages/signup';
import welcome from './pages/welcome';
import balance from './pages/bar/balance';
import home from './pages/bar/home';
import Order from './pages/bar/order';
import Profile from './pages/bar/profile';
import Twofa from './pages/setup/2fa';
import Admin from './pages/setup/admin';
import Enter2fa from './pages/setup/enter2fa';
import Owner from './pages/setup/owner';
import User from './pages/setup/user';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Stripe from './pages/topUp/Stripe';
import Choose from './pages/topUp/Choose';
import Bar from './pages/topUp/Bar';
import Success from './pages/topUp/Success';
import Fail from './pages/topUp/Fail';
import AddUserToCart from './pages/ordering/AddUserToCart';
import FinishOrder from './pages/ordering/FinishOrder';
import ListOrder from './pages/ordering/ListOrder';
const stripePromise = loadStripe(
  'pk_live_51ILS6ICxHwMP8YWYHeoNep5twKhgOrtdpaTJKkRB7SiiSqOyNGzbAzChmbc0kozrdDkBBfvtFHxH6b8ZdGYiCMqr00TsMXta4q'
);
//pk_test_51ILS6ICxHwMP8YWYAgU90Sth9j0o2n4TrWoiGRvqwzzEcOHC54l3DBfPPIbQVC1RfCpv9WV4TUNNWRiGFPknkvmU00XvtrUrYw
// pk_live_51ILS6ICxHwMP8YWYHeoNep5twKhgOrtdpaTJKkRB7SiiSqOyNGzbAzChmbc0kozrdDkBBfvtFHxH6b8ZdGYiCMqr00TsMXta4q
function App() {
  return (
    <Router>
      <Elements stripe={stripePromise}>
        <Route path='/inloggen' component={Login} />
        <Route path='/uitloggen' component={Logout} />
        <Route path='/registreer' component={Signup} />
        <Route path='/' component={welcome} exact />
        {/* Bar links */}
        <Route path='/bar/saldo' component={balance} />
        <Route path='/bar' component={home} exact />
        <Route path='/bar/bestellen' component={Order} />
        <Route path='/bar/profiel' component={Profile} />
        <Route path='/bar/geschiedenis' component={balance} />
        {/* Setup links */}
        <Route path='/instellen/2fa' component={Twofa} />
        <Route path='/instellen/admin/:organisation?' component={Admin} />
        <Route path='/instellen/invullen2fa' component={Enter2fa} />
        <Route
          path='/instellen/eigenaar/:email?/:organisation?/:firstName?/:lastName?'
          component={Owner}
        />
        <Route path='/instellen/gebruiker' component={User} />
        {/* TopUp links */}
        <Route path='/opwaarderen/' component={Choose} exact />
        <Route path='/opwaarderen/stripe' component={Stripe} exact />
        <Route path='/opwaarderen/bar' component={Bar} exact />
        <Route path='/opwaarderen/:id/gelukt' component={Success} exact />
        <Route path='/opwaarderen/gefaald' component={Fail} exact />
        {/* odering links */}
        <Route path='/bestellen/:id' component={AddUserToCart} exact />
        <Route path='/bestellen/:id/afronden' component={FinishOrder} exact />
        <Route path='/bestelling/:id/' component={ListOrder} exact />
      </Elements>
    </Router>
  );
}

export default App;
