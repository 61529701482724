import React, { useEffect } from 'react';
import { getOrderDetails, payOrder } from '../../actions/orderActions';

import { getUserDetails } from '../../actions/userActions';
import { listUsers } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../components/Loader';
import {
  CART_CLEAR_ITEMS,
  CART_CLEAR_USER,
} from '../../constants/orderCartConstants';
import { ORDER_CREATE_RESET } from '../../constants/orderConstants';
const ListOrder = ({ match, history }) => {
  const orderId = match.params.id;

  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading } = orderDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const successPaymentHandler = () => {
    dispatch(payOrder(orderId));
    history.push(`/bar`);
    dispatch({ type: CART_CLEAR_USER });
    dispatch({ type: CART_CLEAR_ITEMS });
    dispatch({ type: ORDER_CREATE_RESET });
  };
  useEffect(() => {
    if (!userInfo) {
      dispatch(getUserDetails('profile'));
    }

    if (userInfo) {
      dispatch(getOrderDetails(orderId));
      dispatch(listUsers(userInfo.organisation));
    }
    // eslint-disable-next-line
  }, [userInfo, orderId, dispatch]);

  return loading ? (
    <Loader />
  ) : (
    <div className='overflow-hidden'>
      {order && users ? (
        <div>
          <div className='flex flex-col items-center justify-center h-[100vh] w-full'>
            <div className=' w-[95vw]  md:w-[30vw] mx-auto '>
              <div className=' w-[95vw]  md:w-[30vw] mx-auto '>
                <div className='w-[95vw] shadow-md rounded-xl lg:w-[30vw] md:w-[50vw] p-5 mb-5 '>
                  <div className=' text-center'>
                    <h1 className='text-5xl font-bold'>De bestelling</h1>
                    <h2 className='text-4xl font-medium mt-2'>
                      Het is gelukt!
                    </h2>
                  </div>
                  <ul className='list-none mt-20 mb-4'>
                    <li>
                      <div className='flex justify-between relative'>
                        <h3 className='text-4xl font-bold'>
                          {order.orderItems}
                        </h3>
                        <span className='mr-8'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='27.87'
                            height='30.967'
                            viewBox='0 0 27.87 30.967'
                          >
                            <g
                              id='Group_100'
                              data-name='Group 100'
                              transform='translate(-313.204 -26.959)'
                            >
                              <path
                                id='cup-outline'
                                d='M3,2,6.1,30.226a3.127,3.127,0,0,0,3.1,2.741H24.677a3.162,3.162,0,0,0,3.1-2.741L30.87,2H3M6.437,5.1h21L24.677,29.87H9.193Z'
                                transform='translate(310.204 24.959)'
                              />
                            </g>
                          </svg>
                          <div className='rounded-full h-7 w-7 bg-gray-200 absolute -top-4 right-5 text-center font-medium'>
                            {order.qty}
                          </div>
                        </span>
                      </div>
                      <hr className='w-full h-[3px] my-4 bg-gray-100 rounded border-0' />
                      <div className='flex flex-col'>
                        {users.map((user) => (
                          <div>
                            {order.users.map((orderUser, index) => (
                              <div className='display flex justify-between mr-10'>
                                {user._id === orderUser ? (
                                  <>
                                    <h3 className='text-xl mr-2 mb-3 text-black font-semibold'>
                                      {user.firstName} {user.lastName}{' '}
                                    </h3>
                                    <h3 className='text-xl font-semibold text-[#34D399]'>
                                      {' '}
                                      {String(order.qtyPP[index]).substring(
                                        0,
                                        1
                                      )}
                                    </h3>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </li>
                  </ul>
                </div>
                <button
                  onClick={successPaymentHandler}
                  className='appearance-none block rounded bg-[#10B981] text-white p-2 w-48 mx-auto'
                >
                  Ik heb alles gepakt!
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>Geen gebruikers gevonden</h1>
      )}
    </div>
  );
};

export default ListOrder;
