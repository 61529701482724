import React, { useEffect } from 'react';
import BoughtItem from './BoughtItem';
import TopUp from './TopUp';
import { listMyOrders } from '../actions/orderActions';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from './Loader';
import { listMyTopUps } from '../actions/topUpActions';
import { getUserDetails } from '../actions/userActions';
import { useState } from 'react';
const History = ({ history }) => {
  const dispatch = useDispatch();
  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, orders } = orderListMy;
  const topUpListMy = useSelector((state) => state.topUpListMy);
  const { topUps } = topUpListMy;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [showOrder, setShowOrder] = useState(false);
  const [showTopUps, setShowTopUps] = useState(true);
  useEffect(() => {
    if (!userInfo) {
      getUserDetails('profile');
    }
    // eslint-disable-next-line
  }, [dispatch, history, userInfo, showTopUps]);

  const swappedOrder = orders?.sort((a, b) => {
    const dateA = new Date(a.paidAt);
    const dateB = new Date(b.paidAt);
    return dateB - dateA;
  });
  const swappedTopUp = topUps?.sort((a, b) => {
    const dateA = new Date(a.paidAt);
    const dateB = new Date(b.paidAt);
    return dateB - dateA;
  });

  const listOrder = () => {
    dispatch(listMyOrders());
    setShowOrder(true);
    setShowTopUps(false);
  };
  const listTopUps = () => {
    dispatch(listMyTopUps());
    setShowOrder(false);
    setShowTopUps(true);
  };
  return loadingOrders ? (
    <Loader />
  ) : (
    <div className='w-full h-full m-4 ml-6'>
      <h1 className='text-4xl font-bold'>Geschiedenis</h1>
      <div className='flex mt-2 '>
        <button
          className={`${showOrder ? 'bg-[#34D399]' : 'bg-[#F3F4F6]'} ${
            showOrder ? 'font-medium' : 'font-normal'
          }  ${showOrder ? 'text-white' : 'text-black'} p-1 rounded-md mr-5`}
          onClick={() => listOrder()}
        >
          Bestellingen
        </button>
        <button
          className={`${showTopUps ? 'bg-[#34D399]' : 'bg-[#F3F4F6]'} ${
            showTopUps ? 'font-medium' : 'font-normal'
          }  ${showTopUps ? 'text-white' : 'text-black'} p-1 rounded-md`}
          onClick={() => listTopUps()}
        >
          Opwaardingen
        </button>
      </div>
      <div className='h-[30rem] overflow-y-auto  scroll-w-0 '>
        {showOrder === true && swappedOrder ? (
          <>
            {swappedOrder.map((order) => (
              <>
                <BoughtItem order={order} user={userInfo} />
              </>
            ))}
          </>
        ) : (
          ''
        )}
        <>
          {showTopUps === true && swappedTopUp ? (
            <>
              {swappedTopUp.map((topUp) => (
                <>
                  <TopUp topUp={topUp} />
                </>
              ))}
            </>
          ) : (
            ''
          )}
        </>
      </div>
    </div>
  );
};

export default History;
