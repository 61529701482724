import React from 'react';

const TwoFAInput = (props) => {
  const elemRefs = [];
  const ref = React.createRef();
  elemRefs.push(ref);
  return (
    <div>
      <input
        type='text'
        name=''
        id=''
        maxLength={1}
        data-index={props.index}
        ref={ref}
        onKeyUp={props.autoTab}
        className='bg-gray-200 appearance-none block p-2 w-10 h-10 rounded'
      />
    </div>
  );
};

export default TwoFAInput;
