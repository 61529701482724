import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { createOrganisation } from '../actions/organisationActions';
const Signup = ({ history }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organisationName, setOrganisationName] = useState('');
  const [iban, setIban] = useState('');
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [postalCode, setpostalCode] = useState('');
  const dispatch = useDispatch();
  const sortedEmail = email.toLowerCase();
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createOrganisation(
        firstName,
        lastName,
        organisationName,
        sortedEmail,
        iban,
        street,
        houseNumber,
        postalCode
      )
    );
    history.push(
      `/instellen/eigenaar/${email}/${organisationName}/${firstName}/${lastName}`
    );
  };

  return (
    <div className='h-max'>
      <div className='grid grid-cols-2 align-center'>
        <section className=' grid place-content-center h-[100vh] bg-[#10B981] text-white text-8xl font-bold text-center'>
          Inschrijven
        </section>
        <section className='grid place-content-center w-full'>
          <form className='w-full max-w-lg'>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-first-name'
                >
                  Voornaam
                </label>
                <input
                  className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                  id='grid-first-name'
                  type='text'
                  placeholder='Voornaam eigenaar'
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </div>
              <div className='w-full md:w-1/2 px-3'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-last-name'
                >
                  Achternaam
                </label>
                <input
                  className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 leading-tight   '
                  id='grid-last-name'
                  type='text'
                  placeholder='Achternaam eigenaar'
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full  px-3 mb-6 md:mb-0'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-first-name'
                >
                  Email
                </label>
                <input
                  className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 mb-3 leading-tight'
                  id='grid-first-name'
                  type='email'
                  placeholder='De email van de eigenaar'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className='w-full  px-3'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-last-name'
                >
                  Organisatie
                </label>
                <input
                  className='appearance-none block w-full bg-white text-gray-700 border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] rounded py-3 px-4 leading-tight   '
                  id='grid-last-name'
                  type='text'
                  placeholder='Jouw vereningsnaam'
                  onChange={(e) => setOrganisationName(e.target.value)}
                  value={organisationName}
                />
              </div>
            </div>

            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full px-3'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-password'
                >
                  IBAN
                </label>
                <input
                  className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981]   '
                  id='grid-password'
                  type='text'
                  placeholder='Waar het geld naar naar toe moet'
                  onChange={(e) => setIban(e.target.value)}
                  value={iban}
                />
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div className='w-full px-3'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-password'
                >
                  Straatnaam
                </label>
                <input
                  className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981]   '
                  id='grid-password'
                  type='text'
                  placeholder='Je adres zonder huisnummer'
                  onChange={(e) => setStreet(e.target.value)}
                  value={street}
                />
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-2'>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-state'
                >
                  Huisnummer
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    className='block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] '
                    id='grid-state'
                    placeholder='Welk nummer'
                    onChange={(e) => setHouseNumber(e.target.value)}
                    value={houseNumber}
                  ></input>
                  <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'></div>
                </div>
              </div>
              <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
                <label
                  className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
                  htmlFor='grid-zip'
                >
                  Postcode
                </label>
                <input
                  className='appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-[#10B981] focus:ring-[#10B981] '
                  id='grid-zip'
                  type='text'
                  placeholder='Postcode'
                  onChange={(e) => setpostalCode(e.target.value)}
                  value={postalCode}
                />
              </div>
            </div>
            <div className='flex items center space-x-6 max-w-lg mx-auto mt-10'>
              <input
                type='submit'
                className='appearance-none block rounded bg-[#10B981] w-[30rem] align-middle text-center py-3 px-4 leading-tight text-white font-bold'
                placeholder='Inschrijven'
                value='Inschrijven'
                onClick={submitHandler}
              ></input>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Signup;
