import { PlusIcon } from '@heroicons/react/outline';
import React from 'react';

const Card = ({ user }) => {
  return (
    <div className='w-24 h-24 2xl:w-28 2xl:h-28 rounded-xl shadow-md grid grid-rows-3 break-words cursor-pointer  '>
      <div className='flex justify-end mt-4  '>
        <PlusIcon
          width={20}
          height={20}
          className=' text-[#10B981] stroke-2 '
        />
      </div>
      <div className='grid place-content-center break-words '>
        <h2 className='font-semibold text-md text-center '>
          {user.firstName + ' ' + user.lastName}
        </h2>
      </div>
    </div>
  );
};

export default Card;
