const BoughtItem = ({ order, user }) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const getUser = () => {
    for (var i = 0; i < order.users.length; i++) {
      let orderUser = order.users[i]._id;
      if (orderUser === user._id) {
        return order.qtyPP[i] * order.productPrice;
      }
    }
  };
  const newPrice = getUser();

  return (
    <div className='w-full mt-8 grid grid-cols-2 grid-rows-2'>
      <h1 className='text-3xl font-bold'>{order.orderItems}</h1>
      <h3 className='text-2xl mt-2 font-medium'>
        €{order?.pricePP || newPrice}
      </h3>
      <h3 className='text-gray-300 font-medium justify-end'>
        {new Date(order.paidAt).toLocaleDateString('nl-NL', options)}
      </h3>
    </div>
  );
};

export default BoughtItem;
