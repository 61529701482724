export const TOPUP_DETAILS_REQUEST = 'TOPUP_DETAILS_REQUEST';
export const TOPUP_DETAILS_SUCCESS = 'TOPUP_DETAILS_SUCCESS';
export const TOPUP_DETAILS_FAIL = 'TOPUP_DETAILS_FAIL';

export const TOPUP_PAY_REQUEST = 'TOPUP_PAY_REQUEST';
export const TOPUP_PAY_SUCCESS = 'TOPUP_PAY_SUCCESS';
export const TOPUP_PAY_FAIL = 'TOPUP_PAY_FAIL';
export const TOPUP_PAY_RESET = 'TOPUP_PAY_RESET';

export const TOPUP_LIST_MY_REQUEST = 'TOPUP_LIST_MY_REQUEST';
export const TOPUP_LIST_MY_SUCCESS = 'TOPUP_LIST_MY_SUCCESS';
export const TOPUP_LIST_MY_FAIL = 'TOPUP_LIST_MY_FAIL';
export const TOPUP_LIST_MY_RESET = 'TOPUP_LIST_MY_RESET';

export const TOPUP_LIST_REQUEST = 'TOPUP_LIST_REQUEST';
export const TOPUP_LIST_SUCCESS = 'TOPUP_LIST_SUCCESS';
export const TOPUP_LIST_FAIL = 'TOPUP_LIST_FAIL';

export const TOPUP_CREATE_REQUEST = 'TOPUP_CREATE_REQUEST';
export const TOPUP_CREATE_SUCCESS = 'TOPUP_CREATE_SUCCESS';
export const TOPUP_CREATE_FAIL = 'TOPUP_CREATE_FAIL';
export const TOPUP_CREATE_RESET = 'TOPUP_CREATE_RESET';
