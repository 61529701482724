import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../actions/userActions';

import { useStripe } from '@stripe/react-stripe-js';
import { getTopUpDetails, payTopUp } from '../../actions/topUpActions';
import { Loader } from '../../components/Loader';
const Success = ({ history, match }) => {
  const topUpId = match.params.id;
  const stripe = useStripe();
  const [success, setSuccess] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const topUpDetails = useSelector((state) => state.topUpDetails);
  const { topUp } = topUpDetails;

  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  );

  const dispatch = useDispatch();
  const submitTopUp = () => {
    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setSuccess(true);
          if (user._id) {
            dispatch(
              payTopUp({
                id: topUpId,
                paymentResult: true,
                userAcc: user,
                transactionNr: paymentIntent.id,
              })
            );
          }
          history.push('/bar');
          break;

        case 'processing':
          break;

        case 'requires_payment_method':
          history.push('/opwaarderen/gefaald');
          break;

        case 'payment_failed':
          history.push('/opwaarderen/gefaald');
          break;

        default:
          break;
      }

      dispatch(getUserDetails('profile'));
    });
  };
  useEffect(() => {
    if (!stripe) {
      return;
    }
    if (success === false) {
      submitTopUp();
    }

    if (!topUp) {
      dispatch(getTopUpDetails(topUpId));
    }
    if (topUp?.isPaid) {
      history.push('/bar');
    }
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!user?.firstName) {
        dispatch(getUserDetails('profile'));
      }
    }
    // eslint-disable-next-line
  }, [
    topUp,
    history.push,
    dispatch,
    topUpId,
    user?.firstName,
    stripe,
    userInfo,
    success,
  ]);

  return (
    <div>
      {success === true ? (
        <div className='h-[100vh] w-[90vw] flex justify-center items-center'>
          <div className='flex flex-col items-center justify-center h-[100vh] w-full'>
            <div className=' w-[95vw]  md:w-[30vw] mx-auto '>
              <div className=' w-[95vw]  md:w-[30vw] mx-auto '>
                <div className='w-[95vw] shadow-md rounded-xl lg:w-[30vw] md:w-[50vw] p-5 mb-5 '>
                  <div className=' text-center flex flex-col items-center justify-center'>
                    <h2 className='text-4xl font-medium mt-2'>
                      Het is gelukt!
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Success;
