import { PlusIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'react-router-dom';
const Card = ({ title, link, background }) => {
  return (
    <div
      className=' w-28 h-28  rounded-xl shadow-md grid grid-rows-3 break-words hover:transition-all hover:bg-[#34D399]'
      style={{ background: `${background ? '#34D399' : '#fff'}` }}
    >
      <div className='flex justify-end mt-4  '>
        <PlusIcon
          width={20}
          height={20}
          className=' text-[#34D399] stroke-2 '
        />
      </div>
      <Link to={link} className='grid place-content-center break-words '>
        <h2 className='font-semibold text-md text-center '>{title}</h2>
      </Link>
    </div>
  );
};

export default Card;
