import React from 'react';
import TwoFAInput from '../../components/twoFAInput';

const Enter2fa = () => {
  const blocks = Array.from({ length: 6 }, (index) => (
    <TwoFAInput key={index} index={index} />
  ));

  return (
    <div className='grid align-center text-center  place-content-center h-[100vh]'>
      <h1 className='text-3xl font-bold'>2FA Code Invoeren</h1>
      <div className='flex flex-row-6 gap-6 mt-10'>{blocks}</div>
    </div>
  );
};

export default Enter2fa;
