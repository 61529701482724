import axios from 'axios';

import {
  TOPUP_CREATE_REQUEST,
  TOPUP_CREATE_SUCCESS,
  TOPUP_CREATE_FAIL,
  TOPUP_DETAILS_FAIL,
  TOPUP_DETAILS_SUCCESS,
  TOPUP_DETAILS_REQUEST,
  TOPUP_LIST_MY_REQUEST,
  TOPUP_LIST_MY_SUCCESS,
  TOPUP_LIST_MY_FAIL,
  TOPUP_PAY_REQUEST,
  TOPUP_PAY_SUCCESS,
  TOPUP_PAY_FAIL,
} from '../constants/topUpConstants';
import { getUserDetails, logout } from './userActions';

export const createTopUp = (topup) => async (dispatch, getState) => {
  console.log('action', { topup });
  try {
    dispatch({
      type: TOPUP_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    console.log(process.env.REACT_APP_API_URL);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/topup`,
      topup,
      config
    );

    dispatch({
      type: TOPUP_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (
      message === 'Verkeerde token, niet hacken he' ||
      message === 'Je hebt geen password token'
    ) {
      dispatch(logout());
    }
    dispatch({
      type: TOPUP_CREATE_FAIL,
      payload: message,
    });
  }
};

export const getTopUpDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOPUP_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/topup/${id}`,
      config
    );

    dispatch({
      type: TOPUP_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (
      message === 'Verkeerde token, niet hacken he' ||
      message === 'Je hebt geen password token'
    ) {
      dispatch(logout());
    }
    dispatch({
      type: TOPUP_DETAILS_FAIL,
      payload: message,
    });
  }
};
export const payTopUp = (topup) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOPUP_PAY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/topup/pay`,
      topup,
      config
    );

    dispatch({
      type: TOPUP_PAY_SUCCESS,
      payload: data,
    });
    dispatch(getUserDetails(userInfo._id));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, token failed') {
      dispatch(logout());
    }
    dispatch({
      type: TOPUP_PAY_FAIL,
      payload: message,
    });
  }
};

export const listMyTopUps = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOPUP_LIST_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/topup/mytopups`,
      config
    );

    dispatch({
      type: TOPUP_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (
      message === 'Verkeerde token, niet hacken he' ||
      message === 'Je hebt geen password token'
    ) {
      dispatch(logout());
    }
    dispatch({
      type: TOPUP_LIST_MY_FAIL,
      payload: message,
    });
  }
};

// Unused at moment will be used soon with stripe
// export const savePaymentMethod = (data) => (dispatch) => {
//   dispatch({
//     type: TOPUP_SAVE_PAYMENT_METHOD,
//     payload: data,
//   });

//   localStorage.setItem('paymentMethod', JSON.stringify(data));
// };
