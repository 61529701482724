import React, { useState } from 'react';
import Card from '../../components/Card';
import Nav from '../../components/Nav';
import Sidenav from '../../components/Sidenav';
import { Loader } from '../../components/Loader';
import { listProducts } from '../../actions/productActions';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from '../../components/Carousel';
import {
  CART_CLEAR_ITEMS,
  CART_CLEAR_USER,
} from '../../constants/orderCartConstants';
const Order = ({ history }) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, products } = productList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [selected, setselected] = useState(false);
  useEffect(() => {
    dispatch({ type: CART_CLEAR_ITEMS });
    dispatch({ type: CART_CLEAR_USER });
    dispatch(listProducts(userInfo.organisation));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className='overflow-hidden'>
      {loading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <Nav />
          <div className='flex w-[100vw]'>
            <Sidenav className='w-1/3' userInfo={userInfo} />
            <div className='flex flex-row w-full md:w-2/3'>
              <div className='w-full'>
                <div className='mb-10'>
                  <div className='flex flex-row w-full h-5 ml-10  '>
                    <h1 className='text-2xl md:text-4xl font-bold'> Dranken</h1>
                  </div>

                  <Carousel show={3}>
                    {products?.map((product) => (
                      <>
                        {product.category === 'Dranken' ? (
                          <div
                            key={uuidv4()}
                            className='p-8'
                            onClick={() =>
                              history.push(`/bestellen/${product._id}`)
                            }
                          >
                            <Card
                              title={product.name}
                              style={{ width: '100%' }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </Carousel>
                </div>
                {userInfo.nixx === true ? (
                  <div className='mb-10'>
                    <div className='flex flex-row w-full h-5 ml-10  '>
                      <h1 className='text-2xl md:text-4xl font-bold'>
                        Alcoholische dranken
                      </h1>
                    </div>

                    <Carousel show={3}>
                      {products?.map((product) => (
                        <>
                          {product.category === 'Alcoholische dranken' ? (
                            <div
                              className='p-8'
                              onClick={() =>
                                history.push(`/bestellen/${product._id}`)
                              }
                            >
                              <Card
                                title={product.name}
                                background={selected}
                                onClick={() => setselected(!selected)}
                                style={{
                                  width: '100%',
                                  background: `${'#212121'}`,
                                }}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </Carousel>
                  </div>
                ) : null}

                <div className='mb-10'>
                  <div className='flex flex-row w-full h-5 ml-10  '>
                    <h1 className='text-2xl md:text-4xl font-bold'> Snacks</h1>
                  </div>

                  <Carousel show={3}>
                    {products?.map((product) => (
                      <>
                        {product.category === 'Snacks' ? (
                          <div
                            key={uuidv4()}
                            className='p-8'
                            onClick={() =>
                              history.push(`/bestellen/${product._id}`)
                            }
                          >
                            <Card
                              title={product.name}
                              style={{ width: '100%' }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </Carousel>
                </div>
                <div className='mb-10'>
                  <div className='flex flex-row w-full h-5 ml-10  '>
                    <h1 className='text-2xl md:text-4xl font-bold'> Eten</h1>
                  </div>

                  <Carousel show={3}>
                    {products?.map((product) => (
                      <>
                        {product.category === 'Eten' ? (
                          <div
                            className='p-8'
                            onClick={() =>
                              history.push(`/bestellen/${product._id}`)
                            }
                          >
                            <Card
                              title={product.name}
                              style={{ width: '100%' }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Order;
