import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../actions/userActions';
import { USER_DETAILS_RESET } from '../constants/userConstants';

const Barpass = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    dispatch({ type: USER_DETAILS_RESET });
    if (userInfo === null || !userInfo || userInfo === undefined) {
      dispatch(getUserDetails('profile'));
    }
    if (userInfo._id) {
      dispatch(getUserDetails(userInfo._id));
    }
    // eslint-disable-next-line
  }, [dispatch, userInfo]);
  const adjustNumber = (num) => {
    const adjustedNum =
      typeof num === 'number' ? num.toFixed(2).replace('.', ',') : 0;
    return adjustedNum;
  };
  return user ? (
    <>
      <h1 className='font-bold text-2xl mb-3 md:ml-6'>
        Welkom {user.firstName}
      </h1>
      <div className='w-80% md:max-w-[17rem] w-[19rem] mb-4 md:m-4 md:ml-6 h-30 xl:h-30 2xl:h-44 bg-[#34D399] rounded-lg drop-shadow-md relative	'>
        <div className='grid grid-rows-3 content-evenly space-y-3 p-3 text-white'>
          <h2 className='text-xl font-bold  '>BarPas</h2>
          <h2 className='text-xl font-semibold pt-2'>
            € {adjustNumber(user.saldo)}
          </h2>
        </div>
        <div className=' absolute right-2 bottom-3'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='27.057'
            height='27.901'
            viewBox='0 0 27.057 27.901'
          >
            <g
              id='Group_75'
              data-name='Group 75'
              transform='translate(-322.943 -377.929)'
            >
              <path
                id='Path_67'
                data-name='Path 67'
                d='M104.88,318.64v19.287a1.306,1.306,0,0,0,1.418,1.154H118.28a1.377,1.377,0,0,0,1.495-1.218V318.64a.5.5,0,0,0-.541-.44H105.421A.5.5,0,0,0,104.88,318.64Z'
                transform='translate(219.12 65.749)'
                fill='none'
                stroke='#fff'
                strokeMiterlimit='10'
                strokeWidth='2'
              />
              <path
                id='Path_68'
                data-name='Path 68'
                d='M104.88,723.417c13.107,0,14.89-.026,14.89-.026'
                transform='translate(219.12 -321.815)'
                fill='none'
                stroke='#fff'
                strokeMiterlimit='10'
                strokeWidth='2'
              />
              <path
                id='Path_8'
                data-name='Path 8'
                d='M119.77,208.083a3.062,3.062,0,0,0-3.083-3.042h-.118a5.121,5.121,0,0,0-4.253-2.236,5.284,5.284,0,0,0-4.9,3.2,2.126,2.126,0,0,0-.424-.07,2.419,2.419,0,0,0-2.11,2.257'
                transform='translate(219.12 176.123)'
                fill='none'
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
              />
              <text
                id='B'
                transform='translate(337 400)'
                fill='#fff'
                fontSize='19'
                fontFamily='Inter-Bold, Inter'
                fontWeight='700'
              >
                <tspan x='0' y='0'>
                  B
                </tspan>
              </text>
            </g>
          </svg>
        </div>
      </div>
    </>
  ) : (
    ''
  );
};

export default Barpass;
