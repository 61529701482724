import { PlusIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import History from '../../components/History';
import Nav from '../../components/Nav';

import Sidenav from '../../components/Sidenav';

const Balance = () => {
  return (
    <div className='overflow-hidden'>
      <Nav />
      <div className='flex w-[100vw]'>
        <Sidenav className='w-1/3' />
        <div className='flex flex-row w-2/3'>
          <div>
            <div className='flex flex-col mb-20'>
              <div className='flex flex-row w-full h-5 ml-10 space-y-32 '>
                <Link
                  to='/opwaarderen'
                  className='w-80 h-12 bg-[#34D399] rounded-md grid place-items-center text-white cursor-pointer'
                >
                  <h3 className='inline font-bold'>
                    <PlusIcon className='inline w-7 mr-2' /> Saldo opwaarderen
                  </h3>
                </Link>
              </div>
              <div className='flex flex-row ml-10 space-x-12 mt-10 h-full '>
                <History />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ShoppingCart /> */}
    </div>
  );
};

export default Balance;
