const BoughtItem = ({ topUp }) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const adjustNumber = (num) => {
    const adjustedNum = Number(num).toFixed(2).replace('.', ',');
    return adjustedNum;
  };
  return (
    <div className='w-full mt-8 grid grid-rows-2'>
      <h3 className='text-3xl mt-2 font-bold'>
        {adjustNumber(topUp.topUpAmount)}
      </h3>
      <h3 className='text-gray-700 font-normal justify-end'>
        {new Date(topUp.paidAt).toLocaleDateString('nl-NL', options)}
      </h3>
    </div>
  );
};

export default BoughtItem;
